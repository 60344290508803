import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueMoment from 'vue-moment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from './store/index'
import VueI18n from 'vue-i18n'
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/theme.bundle.css'
import Utils from './plugins/utils'
import ru from '@/translations/ru'
const moment = require('moment');

let defaultLang = !process.env.DEFAULT_LANG ? 'en' : process.env.DEFAULT_LANG;

VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here
    if (this.locale !== 'en') {
        // proceed to the default implementation
    }

    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
        return 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return (choicesLength < 4) ? 2 : 3;
};
Vue.use(VueI18n)
Vue.use(Utils, { store: store });

let messages = {
    ru
};

const i18n = new VueI18n({
    locale: defaultLang,
    messages,
    silentTranslationWarn: true
});

Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
})

Vue.config.productionTip = false

function removeElementsByClass(className: any){
    const elements = document.getElementsByClassName(className);
    while(elements.length > 0){
        // @ts-ignore
        elements[0].parentNode.removeChild(elements[0]);
    }
}

router.beforeEach((to, from, next) => {
    type typePopStateDetected = {
        popStateDetected: Boolean
    }

    const IsItABackButton = (window as any).popStateDetected as typePopStateDetected
    (window as any).popStateDetected = false

    if (IsItABackButton) {
        removeElementsByClass('modal-backdrop show')
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch('auth/authenticate').then((response: any) => {
            next();
        }).catch((error: any) => {
            next({
                name: 'login',
            })
        })
    } else {
        next();
    }
});

Vue.use(VueMoment, {
    moment
});

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
